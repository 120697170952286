import React, { useRef } from 'react';
import './Join.css';
import emailjs from '@emailjs/browser';

const Join = () => {

    const form = useRef ();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_wi7qhwc', 'template_393nkbl', form.current, 'mjhU-XOnaZvIaGvbU')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };

  return (
    <div className='Join' id='joinUs'>
        <div className='leftJoin'>
           <hr />
            <div>
                <span className='strokeText'>Ready To </span>
                <span>Level Up</span>
            </div>
            <div>
                <span>Your Body </span>
                <span className='strokeText'>With Us?</span>
            </div>
        </div>

        <div className='rightJoin'>
            <form ref={form} className='emailContainer' onSubmit={sendEmail}>
               <input type='email' name='userEmail' placeholder='Enter Your Email Address...' />
               <button className='heroButton joinButton'>Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join