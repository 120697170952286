import React from 'react';
import './Plans.css';
import { plansData } from '../../data/plansData';
import whiteTick from '../../assets/whiteTick.png';

const Plans = () => {
  return (
    <div className='plansContainer' id='plans'>

    <div className="blur planBlurOne"></div>
    <div className="blur planBlurTwo"></div>
    
      <div className='programsHeader' style={{ gap: '2rem' }}>
        <span className='strokeText'>Ready To Start</span>
        <span>Your Journey</span>
        <span className='strokeText'>Now With Us</span>
      </div>

      <div className='plans'>
        {plansData.map((plan, i) => (
          <div className='plan' key={i}>
            {plan.icon}
            <span>{plan.name}</span>
            <span>${plan.price}</span>

            <div className='features'>
              {plan.features.map((feature, i) => (
                <div className='feature'>
                  <img src={whiteTick} alt='' />
                  <span key={i}>{feature}</span>
                </div>
              ))}
            </div>

            <div>
              <span>See More Benefits...</span>
            </div>
            <button className='heroButton'>Join Us</button>

          </div>
        ))}
      </div>

    </div>
  )
}

export default Plans