import React from 'react';
import './Footer.css';
import Github from '../../assets/github.png';
import Instagram from '../../assets/instagram.png';
import LinkedIn from '../../assets/linkedin.png';
import logo from '../../assets/logo.png';

const Footer = () => {
    return (
        <div className='footerConatiner'>
            <hr />

            <div className='footer'>
                <div className='socialLinks'>
                    <a href='https://github.com/' target='_blank'><img src={Github} alt='' /></a>
                    <a href='https://www.linkedin.com/' target='_blank'><img src={LinkedIn} alt='' /></a>
                    <a href='https://www.instagram.com/' target='_blank'> <img src={Instagram} alt='' /></a>
                </div>

            <div className='footerLogo'>
                <img src={logo} alt='' />
            </div>
            </div>

            <div className="blur footerBlurOne"></div>
            <div className="blur footerBlurTwo"></div>

        </div>
    )
}

export default Footer