import React from 'react';
import './Reasons.css';
import image1 from '../../assets/image1.png';
import image2 from '../../assets/image2.png';
import image3 from '../../assets/image3.png';
import image4 from '../../assets/image4.png';
import nb from '../../assets/nb.png';
import adidas from '../../assets/adidas.png';
import nike from '../../assets/nike.png';
import tick from '../../assets/tick.png';

const Reasons = () => {
  return (
    <div className='Reasons' id='reasons'>
      <div className='leftSideReason'>
        <img src={image1} alt='' />
        <img src={image2} alt='' />
        <img src={image3} alt='' />
        <img src={image4} alt='' />
      </div>
      <div className='rightSideReason'>
        <span>Some Reasons</span>

        <div>
          <span className='strokeText'>Why</span>
          <span> Choose Us?</span>
        </div>

        <div className='detailRight'>
          <div>
            <img src={tick} alt='' />
            <span>Over 140+ Expert Coaches</span>
          </div>
          <div>
            <img src={tick} alt='' />
            <span>Train Smarter And Faster Than Before</span>
          </div>
          <div>
            <img src={tick} alt='' />
            <span>1 Free Program For New Mumber</span>
          </div>
          <div>
            <img src={tick} alt='' />
            <span>Relaible Partner</span>
          </div>
          <span className='ourPartner'>Our Partner</span>
          <div className='partners'>
            <img src={nb} alt='' />
            <img src={adidas} alt='' />
            <img src={nike} alt='' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reasons