import React from 'react';
import './Programs.css';
import {programsData} from '../../data/programsData';
import rightArrow from '../../assets/rightArrow.png';

const Programs = () => {
  return (
    <div className='programs' id="programs">
      <div className='programsHeader'>
        <span className='strokeText'>Explore Our</span>
        <span>Programs</span>
        <span className='strokeText'>To Shape You</span>
      </div>

     <div className='programsCategories'>
        {programsData.map((program) => (
         <div className='category'>
          {program.image}
          <span>{program.heading}</span>
          <span>{program.details}</span>
          <div className='joinNow'><span>Join Now</span><img src={rightArrow} alt='' /></div>
         </div>
        ))}
     </div>

    </div>
  )
}

export default Programs
