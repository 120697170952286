import React from 'react';
import Header from '../Header/Header';
import './Hero.css';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import heart from '../../assets/heart.png';
import calories from '../../assets/calories.png';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';

const Hero = () => {

  const transition = {type: 'spring', duration: 3}

  return (
    <div className="hero" id='home'>

    <div className="blur heroBlur"></div>

      <div className="leftSide">
        <Header />
          
        <div className='theBestAd'>
          <motion.div
          initial={{left: '200px'}}
          whileInView={{left: '8px'}}
          transition={{...transition, type: 'tween'}}
          >
          </motion.div>
          <span>The Best Fitness Club In The Town</span>
        </div>
        <div className='heroText'>
          <div>
            <span className='strokeText'>Shape </span>
            <span>Your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div>
            <span>In here we will help you to shape and build your ideal body and live up your life to fullest</span>
          </div>
        </div>

        <div className='figures'>
          <div>
            <span>
             <NumberCounter end={140} start={100} delay='4' preFix="+" />
            </span>
            <span>Expert Coachs</span>
          </div>
          <div>
            <span>
             <NumberCounter end={978} start={800} delay='4' preFix="+" />
            </span>
            <span>Mumber Joined</span>
          </div>
          <div>
            <span>
            <NumberCounter end={50} start={0} delay='4' preFix="+" />
            </span>
            <span>Fitness Programs</span>
          </div>
        </div>

        <div className='heroButtons'>
          <button className='heroButton'>Get Started</button>
          <button className='heroButton'>Learn More</button>
        </div>

      </div>
      <div className="rightSide">
        <button className='heroButton'>Join Now</button>

        <motion.div
        initial={{right: '-1rem'}}
        whileInView={{right: '4rem'}}
        transition={transition}
        className='heartRate'>
          <img src={heart} alt='' />
          <span>Heart Rate</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={hero_image} alt='' className='heroImage' />
        <motion.img
        initial={{right: '11rem'}}
        whileInView={{right: '20rem'}}
        transition={transition} 
        src={hero_image_back} alt='' className='heroImageBack' />

        <motion.div 
        initial={{right: '37rem'}}
        whileInView={{right: '28rem'}}
        transition={transition}
        className='calories'>
          <img src={calories} alt='' />
          <div>
            <span>Calories Burned</span>
            <span>220 kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default Hero